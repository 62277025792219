import vue from 'vue'
import vuex from 'vuex'
import axios from 'axios'

vue.use(vuex);

const getDefaultState = () => {
    return {
        currentStep: 1,
        patientInfo: {
            firstName: '',
            lastName: '',
            dob: '',
            sex: '',
            phone: '',
            email: '',
            isValid: false,
            isVipReservation: false,

        },
        petInfo: {
            petName: '',
            petSpecies: '',
            petSubSpecies: '',
            petBreed: '',
            petColor: '',
            petAge: '',
            petSex: '',
            showPassportSpeciesGeneral: false,
            showPassportSubSpecies: false,
            showPassportPetGender: false,
            showPassportPetBreed: false,
            showPassportColor: false,
            showPassportPetName: false
        },
        visitInfo: {
            chiefComplaint: '',
            diagnosticConcerns: '',
            eta: '',
            modeOfTransport: '',
            visitedBefore: '',
            files: [],
            isValid: false
        },
        referralInfo: {
            selectedClinic: null,
            selectedProvider: null,
            newClinic: {
                name: '',
                phone: ''
            },
            newProvider: {
                title: '',
                firstName: '',
                lastName: '',
                credentials: '',
                email: '',
                phone: '',
                phoneType: ''
            },
            ambulanceDisclaimer: false,
            firstDisclaimer: false,
            secondDisclaimer: false,
            isValid: false
        },
        confirmation: {},
        isLoading: false,
        isSubmitting: false,
        clientId: null,
        clientData: {
            settings: {},
            clinics: [],
            providers: [],
            children: [],
            localizedSettings: {},
            name: '',
            address1: '',
            address2: '',
            state: '',
            city: '',
            zip: '',
            phone: '',
            parentClientId: null,
            clientType: '',
            isPetFacility: false,
            species: null,
            subSpecies: null,
            petSex: null
        },
        clientError: null,
        submissionError: 'An error occured while creating your referral. Please try again later.',
        showSubmissionError: false,
        isAddingClinic: false,
        isAddingProvider: false
    }
}
/*eslint-disable no-debugger*/
export default new vuex.Store({
    state: getDefaultState(),
    actions: {
        resetFormState(ctx) {
            ctx.commit('resetState');
        },
        fetchClientData(ctx) {
            if (!ctx.state.clientId) return;
            ctx.state.isLoading = true;

            const clinics = axios.get(`api/passport/${ctx.state.clientId}/clinics`)
                .then(resp => {
                    ctx.state.clientData.clinics = resp.data;
                })
                .catch(err => {
                    ctx.state.clientError = err.message;
                });

            const settings = axios.get(`api/passport/${ctx.state.clientId}/settings`)
                .then(resp => {
                    if (resp.data) {
                        if (resp.data.children.length) {
                            ctx.state.clientData.children = resp.data.children;
                        }
                        if (resp.data.localizedContent) {
                            ctx.state.clientData.localizedSettings = resp.data.localizedContent;
                        }
                        ctx.state.clientData.settings = resp.data.settings;
                        ctx.state.clientData.credentials = resp.data.credentials;
                        ctx.state.clientData.name = resp.data.clientName;
                        ctx.state.clientData.address1 = resp.data.clientAddress1;
                        ctx.state.clientData.address2 = resp.data.clientAddress2;
                        ctx.state.clientData.state = resp.data.clientState;
                        ctx.state.clientData.city = resp.data.clientCity;
                        ctx.state.clientData.zip = resp.data.clientZip;
                        ctx.state.clientData.phone = resp.data.clientPhone;
                        ctx.state.clientData.clientType = resp.data.clientType;
                        ctx.state.clientData.parentClientId = resp.data.parentClientId;
                        ctx.state.clientData.isPetFacility = resp.data.isPetFacility;
                        ctx.state.clientData.species = resp.data.species;
                        ctx.state.clientData.subSpecies = resp.data.subSpecies;
                        ctx.state.clientData.petSex = resp.data.petSex;
                        ctx.state.petInfo.showPassportSpeciesGeneral = resp.data.showPassportSpeciesGeneral;
                        ctx.state.petInfo.showPassportSubSpecies = resp.data.showPassportSubSpecies;
                        ctx.state.petInfo.showPassportPetGender = resp.data.showPassportPetGender;
                        ctx.state.petInfo.showPassportPetBreed = resp.data.showPassportPetBreed;
                        ctx.state.petInfo.showPassportColor = resp.data.showPassportColor;
                        ctx.state.petInfo.showPassportPetName = resp.data.showPassportPetName;
                    }
                })
                .catch(err => {
                    ctx.state.clientError = err.message;
                });

            Promise.all([clinics, settings])
                .finally(() => ctx.state.isLoading = false);
        },
        submitReferral(ctx, payload) {
            const formData = new FormData();
            // console.log(payload)
            formData.append('model', JSON.stringify(payload.model));
            if (payload.files && payload.files.length) {
                payload.files.forEach((file, idx) => {
                    formData.append('file_' + idx, file);
                });
            }
            return new Promise((resolve, reject) => {
                axios.post(`api/passport/${ctx.state.clientId}/referral`, formData)
                    .then(resp => resolve(resp))
                    .catch(error => { reject(error); });
            });
        }
    },
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        }
    }
})
