<template>
    <v-form v-model="visitInfo.isValid" ref="visitInfo">
        <v-container fluid>
            <v-row class="mb-5" style="max-height: 65px;">
                <v-col cols="6" md="3" sm="6" class="col" style="align-self: center;">
                    <strong class="header">Visit information</strong>
                </v-col>
                <v-col cols="6" md="4" sm="6" class="col d-flex d-md-none d-lg-none justify-end align-center" v-if="localizedSettings">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn fab color="primary" v-bind="attrs" v-on="on" class="elevation-3" style="height: 35px; width: 35px;">
                                <v-icon style="font-size: 18px;">mdi-help</v-icon>
                            </v-btn>
                        </template>
                        <span>{{localizedSettings['passport_form_instructions']}}</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="6" md="8" lg="8" class="col d-none d-md-flex d-lg-flex pl-4">
                    <p style="margin: 0; font-size: 12px; color: #333;">{{localizedSettings['passport_form_instructions']}}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8" lg="8" class="col">
                    <div class="form-group">
                        <label for="chiefComplaint">Chief complaint</label>
                        <v-textarea v-model="visitInfo.chiefComplaint"
                                    name="chiefComplaint"
                                    id="chiefComplaint"
                                    class="mt-1"
                                    required
                                    height="60"
                                    :rules="chiefComplaintRules"
                                    :auto-grow="false"
                                    :no-resize="true"
                                    background-color="white"
                                    dense
                                    solo
                                    outlined>
                        </v-textarea>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8" lg="8" class="col">
                    <div class="form-group">
                        <label for="diagnosticConcerns">Diagnostic concerns / comments <span style="color: #999;">(optional)</span> </label>
                        <v-textarea v-model="visitInfo.diagnosticConcerns"
                                    name="diagnosticConcerns"
                                    id="diagnosticConcerns"
                                    class="mt-1"
                                    height="60"
                                    :rules="diagnosticConcernsRules"
                                    :auto-grow="false"
                                    :no-resize="true"
                                    background-color="white"
                                    dense
                                    solo
                                    outlined>
                        </v-textarea>
                    </div>
                </v-col>
            </v-row>
            <v-row class="mb-5 mb-md-0 mb-lg-0">
                <v-col cols="12" md="3" lg="3" class="col pr-0 pr-md-5 pr-lg-5">
                    <div class="form-group">
                        <label for="lastName">ETA</label>
                        <v-select v-model="visitInfo.eta"
                                  :allow-overflow="false"
                                  :disable-lookup="true"
                                  :items="getEtaValues()"
                                  class="mt-2"
                                  required
                                  background-color="white"
                                  outlined
                                  solo>
                        </v-select>
                    </div>
                </v-col>
                <v-col cols="12" md="5" lg="5" class="col" v-if="settings.displayModeOfTransportOption">
                    <div class="form-group">
                        <label for="lastName">Mode of transport</label>
                        <v-btn-toggle v-model="visitInfo.modeOfTransport" class="mt-2" :dense="$vuetify.breakpoint.xs">
                            <v-btn value="Private" outlined color="primary" light style="width: 30%">Private</v-btn>
                            <v-btn value="Ambulance" outlined color="primary" light style="width: 40%">Ambulance</v-btn>
                            <v-btn value="Other" outlined color="primary" light style="width: 30%">Other</v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
            </v-row>
            <v-row class="mb-6">
                <v-col cols="12">
                    <div class="form-group">
                        <label for="lastName" v-if="localizedSettings">{{localizedSettings['visited_facility_before']}}</label>
                        <v-btn-toggle v-model="visitInfo.visitedBefore" class="has-3-toggles mt-2" :dense="$vuetify.breakpoint.xs" style="max-width: 320px; display: block;">
                            <v-btn value="Yes" outlined color="primary" light>Yes</v-btn>
                            <v-btn value="No" outlined color="primary" light>No</v-btn>
                            <v-btn value="NotSure" outlined color="primary" light>Not sure</v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="9" lg="9" class="col">
                    <v-dialog v-model="fileSizeError"
                              max-width="330">
                        <v-card>
                            <v-card-title class="headline">
                                Maximum file size exceeded
                            </v-card-title>

                            <v-card-text>
                                Please make sure your file size is under 10 MB
                            </v-card-text>

                            <v-card-actions class="justify-center">
                                <v-btn color="primary"
                                       @click="fileSizeError = false">
                                    Got it
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <div class="form-group">
                        <label>Attach patient records <span style="color: #999;">(optional)</span><small class="ml-3" style="color:#999;">PDF, PNG or JPEG formats only. Limited to 3 files.</small></label>
                        <v-file-input ref="fileInput"
                                      v-model="localFiles"
                                      multiple
                                      label="Add files"
                                      @change="filesChanged"
                                      hide-input
                                      class="d-none">
                        </v-file-input>
                        <div class="mt-2 attach-wrapper">
                            <v-btn color="primary" @click="browseFiles" style="display: block;" :disabled="visitInfo.files.length === 3">
                                Browse files
                            </v-btn>
                            <div class="file-list ml-5">
                                <v-tooltip top v-for="(file, index) in visitInfo.files" v-bind:key="index">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip close
                                                @click:close="removeFile(index)"
                                                v-bind="attrs"
                                                v-on="on">
                                            <span>{{file.name}}</span>
                                        </v-chip>
                                    </template>
                                    <span>{{file.name}}</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>

    /*eslint-disable no-useless-escape*/
    /*eslint-disable no-debugger*/
    import ReferralMixin from '../../../mixins/referral'
    import moment from 'moment'

    export default {
        name: 'VisitInfo',
        mixins: [ReferralMixin],
        data() {
            return {
                chiefComplaintRules: [
                    v => !!v || 'Chief complaint is required.',
                    v => /^[a-zA-Z0-9 \r\n!@#$&%?�*'�()=\-_`�.+:;,/\"]*$/.test(v) && v.length >= 4 || 'Chief complaint is invalid.'
                ],
                diagnosticConcernsRules: [
                    v => /^[a-zA-Z0-9 \r\n!@#$&%?�*'�()=\-_`�.+:;,/\"]*$/.test(v) || 'Diagnostic concerns is invalid.'
                ],
                localFiles: [],
                fileSizeError: false
            }
        },
        methods: {
            getEtaValues() {
                const periodsInADay = moment.duration(4, 'hours').as('minutes');
                const timeLabels = [];
                const now = new Date();
                now.setMinutes(now.getMinutes() > 30 ? 30 : 0)
                const startTimeMoment = moment(now);
                for (let i = 0; i <= periodsInADay; i += 15) {
                    startTimeMoment.add(i === 0 ? 0 : 15, 'minutes');
                    timeLabels.push({
                        text: startTimeMoment.format('hh:mm A'),
                        value: startTimeMoment.format('MM/DD/YYYY hh:mm A')
                    });
                }
                timeLabels.splice(0, 2);
                return timeLabels;
            },
            filesChanged() {
                this.visitInfo.files = [
                    ...this.localFiles,
                    ...this.visitInfo.files
                ];
            },
            removeFile(idx) {
                this.visitInfo.files.splice(idx, 1);
            },
            browseFiles() {
                this.$refs.fileInput.$refs.input.click();
            }
        },
        mounted() {
            let tis = this;
            this.$refs.fileInput.$refs.input.accept = '.jpg,.jpeg,.png,.pdf';
            this.$refs.fileInput.$refs.input.onchange = (e) => {
                let file = e.target.files[0];
                if (file) {
                    let size = ((file.size / 1024) / 1024).toFixed(2);
                    if (size >= 10) {
                        tis.fileSizeError = true;
                        tis.localFiles.splice(0, 1);
                        tis.visitInfo.files.splice(0, 1);
                    }
                }
            }
        },
        watch: {
            visitInfo: {
                handler() {
                    this.$refs.visitInfo.validate()
                },
                deep: true
            }
        },
    };
</script>
<style>
    .attach-wrapper {
        display: flex;
    }

    .file-list {
        display: flex;
        align-items: center;
        overflow: auto;
    }

        .file-list .v-chip {
            max-width: 140px;
        }

            .file-list .v-chip:not(:last-of-type) {
                margin-right: 5px;
            }

            .file-list .v-chip .v-chip__content span {
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
            }
</style>