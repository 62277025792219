// ReSharper disable InconsistentNaming

import Vue from 'vue'
import VueRouter from 'vue-router'
import Referral from '../components/referral_form/main'
import Landing from '../components/landing/main'
import Confirmation from '../components/referral_form/confirmation'

Vue.use(VueRouter);

export default new VueRouter({
    routes: [
        {
            name: 'referral',
            path: '/referral/:clientId',
            component: Referral
        },
        {
            name: 'landing',
            path: '/:clientId',
            component: Landing
        },
        {
            name: 'confirmation',
            path: '/:clientId/confirmation/:confirmationNumber',
            component: Confirmation
        },
        {
            name: 'api_ignore',
            path: '/api/*'
        },
        {
            name: 'api_ignore2',
            path: '/api'
        },
        {
            name: 'api_ignore3',
            path: '*'
        }
    ]
});