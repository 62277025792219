import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import './scss/main.scss'
import InputFacade from 'vue-input-facade'

Vue.config.productionTip = false;
Vue.use(InputFacade);

new Vue({
    store,
    vuetify,
    router,
    render: h => h(App)
}).$mount("#app");