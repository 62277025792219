<template>
    <section class="content-wrapper">
        <v-stepper v-model="currentStep" alt-labels style="width: 100%; box-shadow: none; background: transparent; height: 100%;">
            <v-stepper-items style="border: none; box-shadow: none;" v-if="!isPetFacility">
                <v-stepper-content step="1">
                    <patient-info></patient-info>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <visit-info></visit-info>
                </v-stepper-content>
                <v-stepper-content step="3">
                    <referral-info></referral-info>
                </v-stepper-content>
            </v-stepper-items>
            <v-stepper-items style="border: none; box-shadow: none;" v-if="isPetFacility">
                <v-stepper-content step="1">
                    <patient-info></patient-info>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <pet-info></pet-info>
                </v-stepper-content>
                <v-stepper-content step="3">
                    <visit-info></visit-info>
                </v-stepper-content>
                <v-stepper-content step="4">
                    <referral-info></referral-info>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <actions v-if="!$store.state.isSubmitting"></actions>
    </section>
</template>

<script>
    import PatientInfo from './steps/patient_info'
    import VisitInfo from './steps/visit_info'
    import ReferralInfo from './steps/referral_info'
    import ReferralMixin from '../../mixins/referral'
    import PetInfo from './steps/pet_info'

    import Actions from './actions'

    export default {
        name: 'StepperContent',
        mixins: [ReferralMixin],
        components: {
            PatientInfo,
            VisitInfo,
            ReferralInfo,
            Actions,
            PetInfo
        },
    };
</script>