<template>
    <section class="stepper">
        <div v-if="!isPetFacility">
            <div class="step" v-bind:class="{'active': currentStep === 1, 'complete': currentStep > 1}">
                <span class="label">Patient info</span>
                <span class="bubble">
                    <v-icon v-if="currentStep > 1">mdi-check</v-icon>
                    <span v-else>1</span>
                </span>
            </div>
            <div class="step" v-bind:class="{'active': currentStep === 2, 'complete': currentStep > 2}">
                <span class="label">Visit info</span>
                <span class="bubble">
                    <v-icon v-if="currentStep > 2">mdi-check</v-icon>
                    <span v-else>2</span>
                </span>
            </div>
            <div class="step" v-bind:class="{'active': currentStep === 3, 'complete': currentStep > 3}">
                <span class="label">Referral info</span>
                <span class="bubble">
                    <v-icon v-if="currentStep > 3">mdi-check</v-icon>
                    <span v-else>3</span>
                </span>
            </div>
        </div>
        <div v-if="isPetFacility">
            <div class="step" v-bind:class="{'active': currentStep === 1, 'complete': currentStep > 1}">
                <span class="label">Owner</span>
                <span class="bubble">
                    <v-icon v-if="currentStep > 1">mdi-check</v-icon>
                    <span v-else>1</span>
                </span>
            </div>
            <div class="step" v-bind:class="{'active': currentStep === 2, 'complete': currentStep > 2}">
                <span class="label">Pet info</span>
                <span class="bubble">
                    <v-icon v-if="currentStep > 2">mdi-check</v-icon>
                    <span v-else>2</span>
                </span>
            </div>
            <div class="step" v-bind:class="{'active': currentStep === 3, 'complete': currentStep > 3}">
                <span class="label">Visit info</span>
                <span class="bubble">
                    <v-icon v-if="currentStep > 3">mdi-check</v-icon>
                    <span v-else>3</span>
                </span>
            </div>
            <div class="step" v-bind:class="{'active': currentStep === 4, 'complete': currentStep > 4}">
                <span class="label">Referral info</span>
                <span class="bubble">
                    <v-icon v-if="currentStep > 4">mdi-check</v-icon>
                    <span v-else>4</span>
                </span>
            </div>
        </div>
    </section>
</template>

<script>
    import ReferralMixin from '../../mixins/referral'

    export default {
        name: 'Stepper',
        mixins: [ReferralMixin]
    };
</script>
<style>
    .stepper * {
        transition: all 0.16s ease;
    }
    .stepper {
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
        .stepper .step {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 50px 0 50px 0;
            width: 100%;
        }

            .stepper .step:not(:last-of-type):after {
                content: "";
                height: 100px;
                width: 3px;
                position: absolute;
                margin-left: 99px;
                margin-top: 125px;
                background: #ccc;
                transition: all 0.35s ease;
            }

            .stepper .step.complete:after {
                background: var(--v-primary-base);
            }

            .stepper .step .bubble {
                font-family: "Montserrat";
                background: #ccc;
                min-width: 25px;
                min-height: 25px;
                text-align: center;
                color: #fff;
                font-weight: 800;
                border-radius: 50%;
                padding: 3px 1px 0 0px;
                transition: all 0.35s ease;
            }

            .stepper .step.complete .bubble {
                background: var(--v-primary-base);
            }

            .stepper .step .bubble i {
                color: #fff;
                font-size: 16px;
                padding-bottom: 3px;
            }

            .stepper .step .label {
                width: 100px;
                font-weight: 600;
                color: #999;
            }

            .stepper .step.active .bubble {
                background: var(--v-primary-base);
            }
            .stepper .step.active .label {
                color: #333;
                font-weight: 800;
            }
</style>