<template>
    <div class="logo">
        <span class="light">er</span>
        <span class="strong">passport</span>
    </div>
</template>

<script>
export default {
  name: 'Logo',
  components: {
  },

  data: () => ({
    //
  })
};
</script>
<style>
    .logo {
        height: 55px
    }
    .logo span {
        font-family: "Montserrat";
        font-size: 32px;
        color: var(--v-primary-base);
    }
    .logo span.light {
        font-weight: 600;
    }
    .logo span.strong {
        font-weight: 800;
    }
</style>