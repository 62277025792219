<template>  
    <v-container>
        <v-row>
            <v-col cols="12" md="8" lg="8" class="col d-flex d-sm-inline d-md-inline d-lg-inline flex-column justify-center align-center">
                <v-btn @click="prevStep" class="mb-5 mr-0 mb-sm-0 mb-md-0 mb-lg-0 mr-sm-5 mr-md-7 mr-lg-7 action-button" v-if="currentStep > 1">
                    <v-icon left>
                        mdi-chevron-left
                    </v-icon>
                    Go back
                </v-btn>
                <v-btn v-if="!isPetFacility && currentStep < 4" color="secondary" @click="nextStep" class="action-button" :disabled="!currentStepValid">
                    {{nextStepText}}
                    <v-icon right v-if="currentStep !== 3">
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-btn v-if="isPetFacility && currentStep < 5" color="secondary" @click="nextStep" class="action-button" :disabled="!currentStepValid">
                    {{nextStepText}}
                    <v-icon right v-if="currentStep !== 4">
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ReferralMixin from '../../mixins/referral.js'
    export default {
        name: 'Review',
        mixins: [ReferralMixin]
    };
</script>
<style>
    .action-button {
        font-size: 14px !important;
        min-width: 135px !important;
    }
    @media (max-width: 599px) {
        .action-button {
            min-width: 260px !important;
        }
    }
</style>