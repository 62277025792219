<template>
    <v-form v-model="patientInfo.isValid" ref="patientInfo" lazy-validation>
        <v-container fluid>
            <v-row class="mb-5" style="max-height: 65px;">
                <v-col cols="6" md="3" sm="4" class="col" style="align-self: center;">
                    <strong class="header">{{ isPetFacility ? 'Owner information' : 'Patient information' }}</strong>
                </v-col>
                <v-col cols="6" md="4" sm="4" class="col d-flex d-md-none d-lg-none justify-end align-center" v-if="localizedSettings">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn fab color="primary" v-bind="attrs" v-on="on" class="elevation-3" style="height: 35px; width: 35px;">
                                <v-icon style="font-size: 18px;">mdi-help</v-icon>
                            </v-btn>
                        </template>
                        <span>{{localizedSettings['passport_form_instructions']}}</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="6" md="8" lg="8" class="col d-none d-md-flex d-lg-flex pl-4">
                    <p style="margin: 0; font-size: 12px; color: #333;">{{localizedSettings['passport_form_instructions']}}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="8" md="7" lg="7">
                    <div class="form-group">
                        <label for="firstName">First name</label>
                        <v-text-field v-model="patientInfo.firstName"
                                      name="firstName"
                                      id="firstName"
                                      autocomplete="off"
                                      class="mt-2"
                                      :rules="firstNameRules"
                                      @keydown.enter="validate"
                                      outlined
                                      solo
                                      required
                                      validate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="8" md="7" lg="7">
                    <div class="form-group">
                        <label for="lastName">Last name</label>
                        <v-text-field v-model="patientInfo.lastName"
                                      name="lastName"
                                      id="lastName"
                                      autocomplete="off"
                                      class="mt-2"
                                      :rules="lastNameRules"
                                      solo
                                      outlined
                                      required
                                      validate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="$store.state.clientData.settings.allowVipOption && !isPetFacility">
                <v-col sm="8" md="7" lg="7">

                    <div class="row  form-group" style="padding-left:3%">
                        <label for="isVipReservation" @click="patientInfo.isVipReservation = !patientInfo.isVipReservation" style="margin:7px">VÏP patient</label>
                        <!--<label for="isVipReservation" @click="patientInfo.isVipReservation = !patientInfo.isVipReservation">{{localizedSettings['passport_t&c_first_disclaimer']}}</label>-->
                        <v-checkbox v-model="patientInfo.isVipReservation" name="isVipReservation" style="margin-top:0px;padding-top:0px">
                        </v-checkbox>
                        <v-tooltip right class="tooltip-custom"  color="white">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn fab color="primary" v-bind="attrs" v-on="on" class="elevation-3" style="height: 26px; width: 26px; cursor: pointer; background-color: grey !important; top: 6px ">
                                    <v-icon  color="white" style="font-size: 18px;" >mdi-help</v-icon>
                                </v-btn>
                            </template>
                            <span style="color:black">{{tooltip}}</span>
                        </v-tooltip>

                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="3">
                    <div class="form-group">
                        <label for="dob">Date of birth</label>
                        <v-text-field v-facade="'##/##/####'"
                                      v-model="patientInfo.dob"
                                      name="dob"
                                      type="tel"
                                      id="dob"
                                      class="mt-1"
                                      ref="dob"
                                      placeholder="MM/DD/YYYY"
                                      inputmode="numeric"
                                      autocomplete="off"
                                      style="max-width: 160px;"
                                      :rules="dobRules"
                                      solo
                                      outlined
                                      validate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="8" md="7" lg="7" class="mb-3">
                    <div class="form-group">
                        <label>Sex</label>
                        <v-btn-toggle v-model="patientInfo.sex" class="has-3-toggles mt-2" :dense="$vuetify.breakpoint.xs">
                            <v-btn value="Male" outlined color="primary" light>Male</v-btn>
                            <v-btn value="Female" outlined color="primary" light>Female</v-btn>
                            <v-btn value="Other" outlined color="primary" light>Other</v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
            </v-row>
            <v-row class="mt-5">
                <v-col cols="12" sm="6" md="3" lg="3" class="pr-md-5 pr-lg-6 col">
                    <div class="form-group">
                        <label for="phone">Phone <span style="color: #999;">(optional)</span> </label>
                        <v-text-field v-model="patientInfo.phone"
                                      v-facade="'(###)-###-####'"
                                      placeholder="(___)-___-____"
                                      name="phone"
                                      id="phone"
                                      class="mt-1"
                                      type="tel"
                                      autocomplete="off"
                                      style="max-width: 160px"
                                      :rules="phoneRules"
                                      outlined
                                      solo
                                      validate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" sm="8" md="4" lg="4" class="col">
                    <div class="form-group">
                        <label for="email">Email <span style="color: #999;">(optional)</span> </label>
                        <v-text-field v-model="patientInfo.email"
                                      name="email"
                                      type="email"
                                      class="mt-1"
                                      autocomplete="off"
                                      :rules="emailRules"
                                      outlined
                                      solo
                                      validate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    /*eslint-disable no-useless-escape*/
    import referralMixin from '../../../mixins/referral'
    import moment from 'moment'

    export default {
        name: 'PatientInfo',
        mixins: [referralMixin],
        data: () => ({
            tooltip: "Marking your patient as a VIP means that their name will not be visible to staff members at the receiving ED unless they have an Admin role within the ER Passport application. They may also ensure that your patient waits in a private area and if admitted, they will stay in a private room.",
            firstNameRules: [
                v => !!v || 'First name is required',
                v => /^[a-zA-Z0-9 '-.�]+$/.test(v) || 'First name is invalid.',
                v => isNaN(v) || 'First name is invalid'
            ],
            lastNameRules: [
                v => !!v || 'Last name is required',
                v => /^[a-zA-Z0-9 '-.�]+$/.test(v) || 'Last name is invalid.',
                v => isNaN(v) || 'Last name is invalid'
            ],
            dobRules: [
                v => !!v || 'Date of birth is required',
                v => moment(v, 'MM/DD/YYYY', true).isValid() || 'Date of birth is invalid',
                v => moment().diff(v, 'years', false) < 120 || 'Date of birth is invalid',
                v => moment().diff(v, 'years', true) > 0 || 'Date of birth is invalid',
                v => moment(v, 'MM/DD/YYYY', true).years() > 1000 || 'Date of birth is invalid',
            ],
            phoneRules: [
                v => !v || (v && v.length === 14) || 'Phone is invalid'
            ],
            emailRules: [
                v => !v || /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(v) || 'Email is invalid'
            ],
        }),
        watch: {
            patientInfo: {
                handler() {
                    this.$refs.patientInfo.validate()
                },
                deep: true
            }
        },
    };
</script>
<style>
    #phone,
    #dob,
    #providerPhone,
    #clinicPhone {
        letter-spacing: 1px;
    }
    .tooltip-custom {
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 5px #0000001A;
        opacity: 1;
    }
</style>
