<template>
  <v-app>
      <v-main>
          <router-view v-show="!isLoading && !clientError && $store.state.clientData.localizedSettings"></router-view>
          <div v-show="isLoading" class="fully-centered loader">
              <v-progress-circular indeterminate color="primary" :size="150"></v-progress-circular>
              <span>We're getting things ready</span>
          </div>
          <v-container class="fully-centered" v-show="clientError" fill-height>
              <v-row>
                  <v-col class="d-flex justify-center">
                      <v-alert prominent dark color="red darken-2" type="error" border="top">
                          <v-row>
                              <v-col class="text-center">
                                  <p>
                                      {{clientError}}
                                  </p>
                              </v-col>
                          </v-row>
                      </v-alert>
                  </v-col>
              </v-row>
          </v-container>
      </v-main>
  </v-app>
</template>

<script>
    /*eslint-disable no-debugger*/
    import referralMixin from './mixins/referral'
    export default {
        name: 'App',
        mixins: [referralMixin],
        watch: {
            '$store.state.clientData.clientType': function (m) {                
                if (!m) return;
                if (m.toUpperCase() === 'GROUP' && this.$router.currentRoute && !this.$router.currentRoute.name.includes('landing')) {
                    this.$router.push({
                        name: 'landing',
                        params: { clientId: this.selectedFacility }
                    });
                }
                if (m.toUpperCase() === 'FACILITY' && this.$router.currentRoute && !this.$router.currentRoute.name.includes('referral')) {

                    this.$router.push({
                        name: 'referral',
                        params: { clientId: this.$route.params.clientId }
                    });
                }
            },
            '$store.state.clientData.settings.passportQueueEnabled': function (enabled) {
                if (enabled !== undefined && !enabled && this.$store.state.clientData.clientType.toUpperCase() === 'FACILITY') {
                    this.$store.state.clientError = 'Referrals are not enabled for this facility';
                    return;
                }
            },
            '$route.params.clientId': function () {
                this.checkClientId();
            }
        },
        mounted() {
            this.$store.dispatch('resetFormState');
            this.checkClientId();
        },
        computed: {
            isLoading() {
                return this.$store.state.isLoading;
            },
            clientError() {
                return this.$store.state.clientError;
            }
        }
    };
</script>

<style>
    .v-alert {
        height: 100px;
        width: 500px;
    }
    .v-alert__wrapper > i {
        display: none !important;
    }
</style>
