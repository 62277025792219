<template>
    <v-container>
        <v-row>
            <v-col cols="2" class="d-none d-md-flex d-lg-flex py-0"></v-col>
            <v-col cols="12" md="10" lg="10" class="pb-0" style="max-width: 950px;">
                <v-row class="d-none d-sm-flex d-md-flex d-lg-flex">
                    <v-col justify="center" align="center">
                        <logo></logo>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="7" class="d-flex flex-column justify-start align-start pl-5 pl-lg-3 pl-md-3 pb-0 pr-0 pt-0">
                        <span class="clinic-name">{{clientData.name}}</span>
                        <router-link v-if="$store.state.clientData.parentClientId"
                                     :to="{ name: 'landing', params: { clientId: $store.state.clientData.parentClientId }}"
                                     v-slot="{ href, navigate }" exact replace>
                            <a @click="navigate" style="font-weight: 600;">Change location</a>
                        </router-link>
                    </v-col>
                    <v-col cols="5" class="address-container d-flex flex-column pr-5 pr-lg-3 pr-md-3 pl-0 pt-0" style="align-items: flex-end; justify-content: flex-end;">
                        <p>{{clientData.address1}}</p>
                        <p>{{clientData.city}}, {{clientData.state}} {{clientData.zip}}</p>
                        <p>{{clientData.phone}}</p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2" class="d-none d-md-flex d-lg-flex align-center pr-8 pl-0 pt-0">
                <stepper></stepper>
            </v-col>
            <v-col cols="12" md="10" lg="10" class="py-0" style="max-width: 950px;">
                <v-container fluid class="referral-container">
                    <v-row>
                        <v-col>
                            <div class="white-container form-container">
                                <stepper-content></stepper-content>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-snackbar v-model="$store.state.showSubmissionError" light elevation="10" timeout="3000">
            {{$store.state.submissionError}}
            <template v-slot:action="{ attrs }">
                <v-btn color="primary"
                       v-bind="attrs"
                       fab
                       small
                       @click="$store.state.showSubmissionError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import Logo from './logo'
    import Stepper from './stepper'
    import StepperContent from './stepper_content'
    import referralMixin from '../../mixins/referral'

    /*eslint-disable no-debugger*/
    export default {
        name: 'ReferralForm',
        mixins: [referralMixin],
        computed: {
            arrowTop() {
                if (this.currentStep === 1)
                    return '-462px';
                if (this.currentStep === 2)
                    return '-338px';
                if (this.currentStep === 3 && this.isAddingClinic)
                    return '-246px'
                if (this.currentStep === 3 && this.isAddingProvider && !this.isAddingClinic)
                    return '-243px';
                return '-211px';
            }
        },
        methods: {
            backToLanding() {
                this.$router.push({
                    name: 'referral',
                    params: { clientId: this.$store.state.clientData.parentClientId }
                });
            }
        },
        components: {
            Logo,
            Stepper,
            StepperContent
        }
    };
</script>

<style>
    .clinic-name {
        font-size: 26px;
        font-weight: 800;
    }
    .address-container p {
        color: #666;
        font-size: 16px;
        margin: 0;
        line-height: 19px;
        font-weight: 600;
        text-align: right;
    }
    .referral-container {
        width: 100%;
        padding: 0;
    }
    .white-container {
        background: #fff;
        border-radius: 17px;
        box-shadow: 0px 0px 30px #0000001A;
        padding: 10px 30px 20px 30px
    }

    .stepper-container {
        padding-top: 0;
        padding-bottom: 0;
    }

    .content-wrapper {
        min-height: 675px;
    }

    .content-wrapper > .container {
        padding-left: 50px;
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .v-stepper__content {
        padding-bottom: 0;
        padding-left: 40px;
        padding-right: 40px;
    }


    @media (max-width: 780px) {
        .referral-container {
            padding: 0;
        }

        .v-stepper__content {
            padding-left: 15px;
            padding-right: 5px;
        }
    }
</style>
