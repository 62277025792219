import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: "#3BADEA",
                secondary: "#82B232",
                accent: "#2F89BA",
                error: "#b71c1c",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107",
                background: "#F1F1F1"
            }
        }
    }
});