<template>
    <v-container fluid>
        <v-row class="my-md-5 my-lg-5">
            <v-col class="d-flex justify-center">
                <logo></logo>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container class="confirmation-container py-10">
                    <v-row>
                        <v-col class="d-flex justify-center">
                            <h1 style="font-weight: 800">
                                {{localizedSettings['passport_confirmation_headline']}}
                            </h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-container class="referral-summary">
                                <v-row>
                                    <v-col>
                                        <v-row>
                                            <v-col>
                                                <label>patient</label>
                                                <span>{{patientInfo.firstName}} {{patientInfo.lastName}}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <label>receiving emergency department</label>
                                                <span>{{$store.state.clientData.name}}</span>
                                                <span>{{clientData.address1}}</span>
                                                <span>{{clientData.city}}, {{clientData.state}} {{clientData.zip}}</span>
                                                <span>{{clientData.phone}}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col>
                                        <v-row>
                                            <v-col>
                                                <label>eta</label>
                                                <span>{{eta}}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <label>confirmation number</label>
                                                <span>{{confirmationNumber}}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex justify-center">
                            <p class="referral-disclaimer">
                                {{localizedSettings['passport_confirmation_disclaimer']}}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row class="button-row">
                        <v-col class="d-flex justify-center">
                            <v-btn color="primary" class="refer-another" @click="referAnother">Refer another patient</v-btn>
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="secondary" class="print" v-bind="attrs" v-on="on" @click="window.print()"><v-icon>mdi-printer</v-icon></v-btn>
                                </template>
                                <span>Print</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import referralMixin from '../../mixins/referral'
    import logo from './logo'

    export default {
        name: 'Review',
        mixins: [referralMixin],
        computed: {
            confirmationNumber() {
                let unformatted = this.$store.state.confirmation?.confirmationNumber;
                if (!unformatted) return '';
                let formatted = `${unformatted.substring(0, 5)}-${unformatted.substring(5, 10)}-${unformatted.substring(10, 13)}`;
                return formatted.toUpperCase();
            },
            eta() {
                return `${this.visitInfo.eta}`
            },
            window() {
                return window;
            }
        },
        methods: {
            referAnother() {
                let clientId = this.$store.state.clientId;
                this.$store.dispatch('resetFormState');
                this.checkClientId();
                this.$router.push({
                    name: 'referral',
                    params: { clientId: clientId }
                });
            }
        },
        mounted() {
            if (!this.$route.params.confirmationNumber || !this.$store.state.confirmation?.confirmationNumber)
                this.referAnother();
        },
        components: {
            logo
        },
    };
</script>
<style>

    .confirmation-container {
        background: #fff;
        background: #fff;
        border-radius: 17px;
        box-shadow: 0px 0px 30px #0000001A;
    }

    .referral-summary {
        min-height: 200px;
        max-width: 600px;
        width: 80%;
        border: solid 1px #ccc;
        border-radius: 17px;
        padding: 25px 40px 24px 40px;
    }

    .referral-summary .col > label,
    .referral-summary .col > span {
        display: block;
    }

    .referral-summary .col > label {
        text-transform: uppercase;
        color: #999;
        font-weight: 700;
        font-size: 12px;
        margin-bottom: 5px;
    }

    .referral-summary .col > span {
        color: #333;
        font-size: 14px;
        font-weight: 700;
    }

    .referral-disclaimer {
        max-width: 600px;
        text-align: center;
        font-size: 12px;
        color: #707070;
    }

    .refer-another {
        height: 60px !important;
        width: 250px;
        font-size: 14px !important;
    }

    .print {
        height: 60px !important;
        margin-left: 10px
    }
    
    @media print {
        .logo,
        .button-row {
            visibility: hidden;
            display: none;
        }
        .confirmation-container {
            box-shadow: none;
        }
    }

</style>