<template>
    <v-form v-model="referralInfo.isValid" ref="referralInfo">
        <v-container v-if="!isSubmitting">
            <v-row class="mb-5" style="max-height: 65px;">
                <v-col cols="6" md="3" sm="6" class="col" style="align-self: center;">
                    <strong class="header">Referral information</strong>
                </v-col>
                <v-col cols="6" md="4" sm="4" class="col d-flex d-md-none d-lg-none justify-end align-center" v-if="localizedSettings">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn fab color="primary" v-bind="attrs" v-on="on" class="elevation-3" style="height: 35px; width: 35px;">
                                <v-icon style="font-size: 18px;">mdi-help</v-icon>
                            </v-btn>
                        </template>
                        <span>{{localizedSettings['passport_form_instructions']}}</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="6" md="8" lg="8" class="col d-none d-md-flex d-lg-flex pl-4">
                    <p style="margin: 0; font-size: 12px; color: #333;">{{localizedSettings['passport_form_instructions']}}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="7" class="col">
                    <div class="form-group">
                        <label for="selectedClinic">Referring clinic name</label>
                        <v-select v-model="referralInfo.selectedClinic"
                                  @change="clinicChanged"
                                  name="selectedClinic"
                                  :rules="selectClinic"
                                  :items="clinics"
                                  :allow-overflow="false"
                                  :disable-lookup="true"
                                  item-text="name"
                                  item-value="id"
                                  placeholder="Select a clinic"
                                  height="40"
                                  class="mt-1"
                                  background-color="white"
                                  required
                                  outlined
                                  solo>
                        </v-select>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="!isCreatingClinic">
                <v-col cols="12" md="7" class="col">
                    <div class="form-group">
                        <label for="selectedProvider">Provider name</label>
                        <v-select v-model="referralInfo.selectedProvider"
                                  @change="providerChanged"
                                  name="selectedProvider"
                                  :rules="selectProvider"
                                  :items="providers"
                                  :allow-overflow="false"
                                  :disable-lookup="true"
                                  :disabled="!referralInfo.selectedClinic"
                                  item-text="name"
                                  item-value="id"
                                  required
                                  placeholder="Select a provider"
                                  height="40"
                                  class="mt-1"
                                  background-color="white"
                                  outlined
                                  solo>
                        </v-select>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="isCreatingClinic">
                <v-col cols="12" md="4" lg="4" class="col">
                    <div class="form-group">
                        <label for="clinicName">Clinic name</label>
                        <v-text-field v-model="referralInfo.newClinic.name"
                                      name="clinicName"
                                      id="clinicName"
                                      autocomplete="off"
                                      class="mt-2"
                                      :rules="clinicNameRules"
                                      maxlength="100"
                                      required
                                      outlined
                                      solo
                                      validate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" md="3" lg="3" class="col pl-md-5 pl-lg-5">
                    <div class="form-group">
                        <label for="clinicPhone">Clinic phone</label>
                        <v-text-field v-model="referralInfo.newClinic.phone"
                                      name="clinicPhone"
                                      id="clinicPhone"
                                      autocomplete="off"
                                      class="mt-2"
                                      :rules="phoneRules"
                                      v-facade="'(###)-###-####'"
                                      placeholder="(___)-___-____"
                                      required
                                      outlined
                                      solo
                                      validate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="isCreatingClinic">
                <v-col cols="12" class="col mb-5">
                    <hr />
                </v-col>
            </v-row>
            <v-row v-if="isCreatingProvider">
                <v-col cols="12" sm="4" md="4" lg="4" class="col">
                    <div class="form-group">
                        <label for="providerFirstName">First name</label>
                        <v-text-field v-model="referralInfo.newProvider.firstName"
                                      name="providerFirstName"
                                      id="providerFirstName"
                                      required
                                      autocomplete="off"
                                      class="mt-2"
                                      :rules="firstNameRules"
                                      outlined
                                      solo
                                      validate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" class="col px-0 px-sm-3 px-md-3 px-lg-3">
                    <div class="form-group">
                        <label for="providerLastName">Last name</label>
                        <v-text-field v-model="referralInfo.newProvider.lastName"
                                      name="providerLastName"
                                      id="providerLastName"
                                      autocomplete="off"
                                      required
                                      class="mt-2"
                                      :rules="lastNameRules"
                                      outlined
                                      solo
                                      validate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" sm="2" md="2" lg="2" class="col">
                    <div class="form-group">
                        <label for="providerCreds">Credentials</label>
                        <v-select v-model="referralInfo.newProvider.credentials"
                                  name="providerCreds"
                                  id="providerCreds"
                                  :items="credentials"
                                  :allow-overflow="false"
                                  :disable-lookup="true"
                                  :rules="required"
                                  required
                                  height="40"
                                  class="mt-2"
                                  background-color="white"
                                  outlined
                                  solo>
                        </v-select>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="isCreatingProvider">
                <v-col cols="12" sm="4" md="4" lg="4" class="col">
                    <div class="form-group">
                        <label for="providerEmail">
                            Email
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon small v-bind="attrs" v-on="on">mdi-information</v-icon>
                                </template>
                                <p>
                                    We do not publish your email anywhere patients can see it.
                                    We use it to send alerts to update you on the patient status.
                                </p>

                            </v-tooltip>
                        </label>
                        <v-text-field v-model="referralInfo.newProvider.email"
                                      name="providerEmail"
                                      id="providerEmail"
                                      autocomplete="off"
                                      class="mt-2"
                                      :rules="emailRules"
                                      outlined
                                      solo
                                      required
                                      validate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
                <v-col cols="9" sm="5" md="3" lg="3" class="col pl-0 pl-sm-5 pl-md-5 pl-lg-5">
                    <div class="form-group">
                        <label for="providerPhone">
                            Phone
                            <span style="color: #999; margin-right: 5px;">(optional)</span>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon small v-bind="attrs" v-on="on">mdi-information</v-icon>
                                </template>
                                <span>We do not publish your phone anywhere patients can see it. We use it to send alerts to update you on the patient status.</span>
                            </v-tooltip>
                        </label>
                        <v-text-field v-model="referralInfo.newProvider.phone"
                                      name="providerPhone"
                                      id="providerPhone"
                                      autocomplete="off"
                                      class="mt-2 provider-phone"
                                      v-facade="'(###)-###-####'"
                                      placeholder="(___)-___-____"
                                      :rules="phoneRules"
                                      outlined
                                      solo
                                      validate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
                <v-col cols="3" md="2" lg="2" class="col">
                    <div class="form-group">
                        <label for="phoneType">Type</label>
                        <v-select v-model="referralInfo.newProvider.phoneType"
                                  name="phoneType"
                                  id="phoneType"
                                  :items="phoneTypes"
                                  :allow-overflow="false"
                                  :disable-lookup="true"
                                  :rules="phoneTypeRules"
                                  height="40"
                                  class="mt-2 phone-type"
                                  background-color="white"
                                  outlined
                                  solo>
                        </v-select>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="localizedSettings">
                <v-col>
                    <v-dialog v-model="tcModal"
                              max-width="800">
                        <v-card style="padding: 10px 15px 10px 15px">
                            <v-card-title class="headline" style="padding-bottom: 20px; font-weight: 700">
                                {{localizedSettings['passport_t&c_title']}}
                                <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
                                <v-btn fab @click="tcModal=false" x-small><v-icon>mdi-close</v-icon></v-btn>
                            </v-card-title>
                            <v-card-text v-html="localizedSettings['passport_t&c_content']"></v-card-text>
                        </v-card>
                    </v-dialog>
                    <div class="tc-row">
                        <span class="title">TERMS & CONDITIONS</span>
                        <div class="tc-checkbox" v-if="visitInfo.modeOfTransport !== 'Ambulance'">
                            <v-checkbox v-model="referralInfo.ambulanceDisclaimer" name="ambulanceDisclaimer" required>
                            </v-checkbox>
                            <label for="ambulanceDisclaimer" @click="referralInfo.ambulanceDisclaimer = !referralInfo.ambulanceDisclaimer">The patient understands the risks of declining ambulance transportation if they are being referred for life-threatening conditions. <a @click="tcModal = true" style="font-weight: 600;">What does this mean?</a></label>
                        </div>
                        <div class="tc-checkbox">
                            <v-checkbox v-model="referralInfo.firstDisclaimer" name="firstDisclaimer" required>
                            </v-checkbox>
                            <label for="fistDisclaimer" @click="referralInfo.firstDisclaimer = !referralInfo.firstDisclaimer">{{localizedSettings['passport_t&c_first_disclaimer']}}</label>
                        </div>
                        <div class="tc-checkbox">
                            <v-checkbox v-model="referralInfo.secondDisclaimer" name="secondDisclaimer" required>
                            </v-checkbox>
                            <label for="secondDisclaimer" @click="referralInfo.secondDisclaimer = !referralInfo.secondDisclaimer">{{localizedSettings['passport_t&c_second_disclaimer']}}</label>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div v-else class="loader" style="height: 570px">
            <v-progress-circular indeterminate color="primary" :size="150"></v-progress-circular>
            <span>We're securely transmitting your information</span>
        </div>
    </v-form>
</template>

<script>
    /*eslint-disable no-useless-escape*/
    /*eslint-disable no-debugger*/
    import ReferralMixin from '../../../mixins/referral'

    export default {
        name: 'ReferralInfo',
        mixins: [ReferralMixin],
        data() {
            return {
                selectClinic: [
                    v => !!v || 'Referring clinic  is required',
                ],
                selectProvider: [
                    v => !!v || 'Provider is required',
                ],
                phoneRules: [
                    v => !v || v && v.length === 14 || 'Phone is invalid'
                ],
                phoneTypeRules: [
                    v => !this.referralInfo.newProvider.phone.length || (this.referralInfo.newProvider.phone && !!v) || 'Required'
                ],
                emailRules: [
                    v => !!v || 'Email is required',
                    v => /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(v) || 'Email is invalid'
                ],
                clinicNameRules: [
                    v => !!v || 'Clinic name is required',
                    v => v.length <= 100 || 'Clinic name length exceeded'
                ],
                firstNameRules: [
                    v => !!v || 'First name is required',
                    v => /^[a-zA-Z0-9 '-.�]+$/.test(v) || 'First name is invalid.',
                    v => isNaN(v) || 'First name is invalid'
                ],
                lastNameRules: [
                    v => !!v || 'Last name is required',
                    v => /^[a-zA-Z0-9 '-.�]+$/.test(v) || 'Last name is invalid.',
                    v => isNaN(v) || 'Last name is invalid'
                ],
                required: [
                    v => !!v || 'Required'
                ],
                tcModal: false,
                phoneTypes: ['Work', 'Cell', 'Home']
            }
        },
        computed: {
            clinics() {
                let existingClinics = this.$store.state.clientData.clinics || [];
                return [...existingClinics, { id: -1, name: 'Add new', providers: [] }]
            },
            providers() {
                if (!this.referralInfo.selectedClinic) return [];
                return [...this.clinics.filter(x => x.id === this.referralInfo.selectedClinic)[0].providers.map(p => {
                    return {
                        id: p.id,
                        name: `${p.firstName} ${p.lastName}, ${p.credentials}`
                    }
                }), { id: -1, name: 'Add new' }]
            },
            isCreatingClinic() {
                return this.referralInfo.selectedClinic === -1;
            },
            isCreatingProvider() {
                return this.referralInfo.selectedProvider === -1 || this.isCreatingClinic;
            },
            isSubmitting() {
                return this.$store.state.isSubmitting;
            },
            credentials() {
                return this.$store.state.clientData.credentials;
            }
        },
        methods: {
            clinicChanged() {
                this.$store.state.isAddingClinic = this.referralInfo.selectedClinic === -1;
            },
            providerChanged() {
                this.$store.state.isAddingProvider = this.referralInfo.selectedProvider === 0;
            }
        },
        watch: {
            referralInfo: {
                handler() {
                    this.$refs.referralInfo.validate()
                },
                deep: true
            }
        },
    };
</script>
<style>
    .tc-row .title {
        color: #999;
        text-transform: uppercase;
        font-size: 12px !important;
        font-weight: 700;
    }

    .tc-row .tc-checkbox {
        font-size: 14px;
    }

    .tc-row .tc-checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
        margin-top: 5px;
    }

    .tc-row label {
        font-size: 13px;
    }

    .terms-and-conditions {
        color: #333;
        font-weight: 400;
        font-size: 14px;
    }

        .terms-and-conditions ul {
            margin-bottom: 10px;
        }

    .provider-phone fieldset {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .phone-type fieldset {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
</style>