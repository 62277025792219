<template>
    <v-form v-model="petInfo.isValid" ref="petInfo" lazy-validation>
        <v-container fluid>
            <v-row>
                <v-col sm="8" md="7" lg="7">
                    <div class="form-group" v-if="petInfo.showPassportPetName">
                        <label for="petName">Pet Name</label>
                        <v-text-field v-model="petInfo.petName"
                                      name="petName"
                                      id="petName"
                                      autocomplete="off"
                                      class="mt-2"
                                      :rules="petNameRules"
                                      outlined
                                      solo
                                      required
                                      validate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="8" md="7" lg="7">
                    <div class="form-group" v-if="petInfo.showPassportSpeciesGeneral">
                        <label for="petSpecies">Pet Species General</label>
                        <v-select v-model="petInfo.petSpecies"
                                  :items="speciesList"
                                  :allow-overflow="false"
                                  name="petSpecies"
                                  id="petSpecies"
                                  ref="petSpecies"
                                  :rules="petSpeciesRules"
                                  background-color="white"
                                  style="max-width: 400px;"
                                  dense
                                  outlined>
                        </v-select>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="8" md="7" lg="7">
                    <div class="form-group" v-if="petInfo.showPassportSubSpecies">
                        <label for="petSubSpecies">Pet Sub Species</label>
                        <v-select v-model="petInfo.petSubSpecies"
                                  :items="petSubSpecies"
                                  :allow-overflow="false"
                                  name="petSubSpecies"
                                  id="petSubSpecies"
                                  ref="petSubSpecies"
                                  background-color="white"
                                  style="max-width: 400px;"
                                  dense
                                  outlined>
                        </v-select>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="8" md="7" lg="7">
                    <div class="form-group" v-if="petInfo.showPassportPetBreed">
                        <label for="petBreed">Pet Breed</label>
                        <v-text-field v-model="petInfo.petBreed"
                                      name="petBreed"
                                      id="petBreed"
                                      ref="petBreed"
                                      background-color="white"
                                      autocomplete="off"
                                      style="max-width: 400px;"
                                      :rules="petBreedRules"
                                      dense
                                      solo
                                      outlined
                                      valdiate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="8" md="7" lg="7">
                    <div class="form-group" v-if="petInfo.showPassportColor">
                        <label for="petColor">Pet Color</label>
                        <v-text-field v-model="petInfo.petColor"
                                      name="PetColor"
                                      id="petColor"
                                      ref="petColor"
                                      background-color="white"
                                      autocomplete="off"
                                      style="max-width: 400px;"
                                      :rules="petColorRules"
                                      dense
                                      solo
                                      outlined
                                      valdiate-on-blur>
                        </v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="8" md="7" lg="7">
                    <div class="form-group" v-if="petAgeInputType === 'CALENDER_DATE'">
                        <label for="petApproximateAge">{{ localizedSettings['online_pet_approx_age_label'] }}</label>
                        <v-text-field v-facade="'##/##/####'"
                                      v-model="petInfo.petAge"
                                      name="petApproximateAge"
                                      type="text"
                                      class="mt-1"
                                      id="petApproximateAge"
                                      ref="petApproximateAge"
                                      background-color="white"
                                      autocomplete="off"
                                      :rules="petApproxCalenderAgeAgeRules"
                                      placeholder="MM/DD/YYYY"
                                      solo
                                      outlined
                                      validate-on-blur>
                        </v-text-field>
                    </div>
                    <div class="form-group" v-if="petAgeInputType === 'PICKLIST'">
                        <label for="petApproximateAge">{{ localizedSettings['online_pet_approx_age_label'] }}</label>
                        <v-select v-model="petInfo.petAge"
                                  :items="this.$store.state.settings.approximateAgeValues"
                                  :allow-overflow="false"
                                  class="mt-1"
                                  name="approxAge"
                                  id="petApproximateAge"
                                  ref="petApproximateAge"
                                  background-color="white"
                                  outlined>
                        </v-select>
                    </div>
                    <div class="form-group" v-if="petAgeInputType === 'FREE_TEXT'">
                        <label for="petApproximateAge">{{ localizedSettings['online_pet_approx_age_label'] }}</label>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="petInfo.petAge"
                                              name="petApproximateAge"
                                              background-color="white"
                                              id="petApproximateAge"
                                              ref="petApproximateAge"
                                              class="mt-1"
                                              autocomplete="off"
                                              :rules="petApproximateAgeRules"
                                              solo
                                              outlined
                                              validate-on-blur>
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-select v-model="petInfo.petApproxAgeType"
                                          :items="this.$store.state.settings.approximateAgeTypes"
                                          :allow-overflow="false"
                                          class="mt-1"
                                          name="petApproxAgeType"
                                          id="petApproxAgeType"
                                          ref="petApproxAgeType"
                                          background-color="white"
                                          outlined>
                                </v-select>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="8" md="7" lg="7">
                    <div class="form-group" v-if="petInfo.showPassportPetGender">
                        <label for="petSex">Pet Sex</label>
                        <v-select v-model="petInfo.petSex"
                                  :items="petSexList"
                                  :allow-overflow="false"
                                  name="petSex"
                                  id="petSex"
                                  ref="petSex"
                                  :rules="petSexRules"
                                  background-color="white"
                                  style="max-width: 400px;"
                                  dense
                                  outlined>
                        </v-select>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    /*eslint-disable no-useless-escape*/
    import referralMixin from '../../../mixins/referral'
    import moment from 'moment'

    export default {
        name: 'PetInfo',
        mixins: [referralMixin],
        data: () => ({
            petNameRules: [
                v => !!v || 'Pet name is required',
                v => /^[a-zA-Z0-9 '-.�]+$/.test(v) || 'Pet name is invalid.',
                v => isNaN(v) || 'Pet name is invalid'
            ],
            petSpeciesRules: [
                v => !!v || 'Pet species is required',
            ],
            petBreedRules: [
                v => !!v || 'Pet breed is required',
            ],
            petColorRules: [
                v => !!v || 'Pet color is required'
            ],
            petApproximateAgeRules: [
                v => !isNaN(v) || 'Age should be numeric'
            ],
            petApproxFreeTextAgeRules: [
                v => !!v || 'Date of birth is required',
                v => moment(v, 'MM/DD/YYYY', true).isValid() || 'Date of birth is invalid',
                v => moment().diff(v, 'years', false) < 120 || 'Date of birth is invalid',
                v => moment().diff(v, 'years', true) > 0 || 'Date of birth is invalid',
                v => moment(v, 'MM/DD/YYYY', true).years() > 1000 || 'Date of birth is invalid',
            ],
            petApproxCalenderAgeAgeRules: [
                v => !!v || 'Date of birth is required',
                v => moment(v, 'MM/DD/YYYY', true).isValid() || 'Date of birth is invalid',
                v => moment().diff(v, 'years', false) < 120 || 'Date of birth is invalid',
                v => moment().diff(v, 'years', true) > 0 || 'Date of birth is invalid',
                v => moment(v, 'MM/DD/YYYY', true).years() > 1000 || 'Date of birth is invalid',
            ],
            petSexRules: [
                v => !!v || 'Pet sex is required',
            ]
        }),
        watch: {
            petInfo: {
                handler() {
                    this.$refs.petInfo.validate()
                },
                deep: true
            }
        },
        computed: {
            formData() {
                return this.$store.state.form.petInfo.data;
            },
            speciesList() {
                if (!this.species) return [];
                return this.species.map(x => x.speciesName);
            },
            petSubSpecies() {
                if (!this.$store.state.petInfo.petSpecies) return [];
                let tis = this;
                let speciesId = this.species.filter(x => x.speciesName === tis.petInfo.petSpecies)[0].id;
                return this.subSpecies.filter(x => x.speciesId === speciesId)
                    .map(x => x.subSpeciesName);
            },
            petSexList() {
                if (!this.petSex) return [];
                return this.petSex.map(x => x.petGenderName);
            },
            petNameValid() {
                if (!this.formData.petName || !this.$refs.petName)
                    return false;
                return this.$refs.petName.validate();
            },
            //petSpeciesValid() {
            //    if (!this.formData.petSpecies || !this.$refs.petSpecies)
            //        return false;
            //    return this.$refs.petSpecies.validate();
            //},
            //petSubSpeciesValid() {
            //    if (!this.formData.petSubSpecies || !this.$refs.petSubSpecies)
            //        return false;
            //    return this.$refs.petSubSpecies.validate();
            //},
            petColorValid() {
                if (!this.formData.petColor || !this.$refs.petColor)
                    return false;
                return this.$refs.petColor.validate();
            },
            petBreedValid() {
                if (!this.formData.petBreed || !this.$refs.petBreed)
                    return false;
                return this.$refs.petBreed.validate();
            }
        },
    };
</script>
<style>
    #phone,
    #dob,
    #providerPhone,
    #clinicPhone {
        letter-spacing: 1px;
    }

    .tooltip-custom {
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 5px #0000001A;
        opacity: 1;
    }
</style>