/*eslint-disable no-useless-escape*/
/*eslint-disable no-debugger*/

export default {
    computed: {
        settings() { return this.$store.state.clientData.settings; },
        currentStep() { return this.$store.state.currentStep; },
        patientInfo() { return this.$store.state.patientInfo; },
        petInfo() { return this.$store.state.petInfo; },
        visitInfo() { return this.$store.state.visitInfo; },
        referralInfo() { return this.$store.state.referralInfo; },
        review() { return this.$store.state.review; },
        clientData() { return this.$store.state.clientData; },
        localizedSettings() { return this.$store.state.clientData.localizedSettings },
        isPetFacility() { return this.$store.state.clientData.isPetFacility; },
        species() { return this.$store.state.clientData.species },
        subSpecies() { return this.$store.state.clientData.subSpecies },
        petSex() { return this.$store.state.clientData.petSex },
        petAgeInputType() { return 'CALENDER_DATE' },
        nextStepText() {
            if (this.isPetFacility) {
                switch (this.currentStep) {
                    case 1:
                        return 'Continue to pet info';
                    case 2:
                        return 'Continue to visit info';
                    case 3:
                        return 'Continue to referral info';
                    case 4:
                        return 'Submit';
                }

            } else {
                switch (this.currentStep) {
                    case 1:
                        return 'Continue to visit info';
                    case 2:
                        return 'Continue to referral info';
                    case 3:
                        return 'Submit';
                }
            }
            return '';
        },
        currentStepValid() {
            if (this.currentStep === 1) {
                return this.patientInfo.isValid && this.patientInfo.sex && this.patientInfo.firstName && this.patientInfo.dob;
            }
            if (this.currentStep === 2) {
                if (this.isPetFacility) {
                    return this.petInfo.isValid;
                }
                return this.visitInfo.isValid
                    && (this.visitInfo.modeOfTransport || !this.settings.displayModeOfTransportOption)
                    && (this.visitInfo.visitedBefore != undefined && this.visitInfo.visitedBefore.length)
                    && this.visitInfo.eta;
            }
            if (this.currentStep === 3 || this.currentStep === 4) {
                if (this.isPetFacility && this.currentStep === 3) {
                    return this.visitInfo.isValid
                        && (this.visitInfo.modeOfTransport || !this.settings.displayModeOfTransportOption)
                        && (this.visitInfo.visitedBefore != undefined && this.visitInfo.visitedBefore.length)
                        && this.visitInfo.eta;
                }
                const disclaimerCheck = this.referralInfo.firstDisclaimer
                    && this.referralInfo.secondDisclaimer
                    && (this.visitInfo.modeOfTransport !== 'Ambulance' ? this.referralInfo.ambulanceDisclaimer : true);
                const newClinicCheck = this.referralInfo.newClinic.name && this.referralInfo.newClinic.phone;
                const newProviderCheck =
                    this.referralInfo.newProvider.firstName
                    && this.referralInfo.newProvider.lastName
                    && this.referralInfo.newProvider.credentials
                    && this.referralInfo.newProvider.email && /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(this.referralInfo.newProvider.email)
                    && (!this.referralInfo.newProvider.phone || (this.referralInfo.newProvider.phone && this.referralInfo.newProvider.phone.length === 14 && this.referralInfo.newProvider.phoneType));
                return this.referralInfo.isValid &&
                    disclaimerCheck &&
                    (!this.referralInfo.selectedClinic ? newClinicCheck && newProviderCheck : true) &&
                    (!this.referralInfo.selectedProvider ? newProviderCheck : true) &&
                    (!this.referralInfo.selectedProvider && !this.referralInfo.selectedClinic
                        ? newClinicCheck && newProviderCheck
                        : true);
            }
            return false;
        },
        isAddingClinic() { return this.$store.state.isAddingClinic; },
        isAddingProvider() { return this.$store.state.isAddingProvider; },
    },
    methods: {
        checkClientId() {
            const clientId = this.$route.params.clientId;
            this.$store.state.clientError = null;
            if (clientId) {
                if (isNaN(clientId)) {
                    this.$store.state.clientError = 'Facility ID must be a number';
                    return;
                }
                else if (clientId.toString().length !== 6) {
                    this.$store.state.clientError = 'Invalid Facility ID';
                }
                else {
                    this.$store.dispatch('resetFormState');
                    this.$store.state.clientId = clientId;
                    this.$store.dispatch('fetchClientData');
                }
            }
            else {
                this.$store.state.clientError = 'Facility ID is required';
            }
        },
        prevStep() {
            if (this.$store.state.currentStep > 1)
                this.$store.state.currentStep--;
        },
        nextStep() {
            if (this.isPetFacility) {
                if (this.$store.state.currentStep === 4) {
                    this.submitReferral();
                    return;
                }
                if (this.$store.state.currentStep < 5)
                    this.$store.state.currentStep++;
            }
            else {
                if (this.$store.state.currentStep === 3) {
                    this.submitReferral();
                    return;
                }
                if (this.$store.state.currentStep < 4)
                    this.$store.state.currentStep++;
            }
        },
        submitReferral() {
            const model = {
                patient: {
                    firstName: this.patientInfo.firstName,
                    lastName: this.patientInfo.lastName,
                    dob: this.patientInfo.dob,
                    phone: this.patientInfo.phone,
                    email: this.patientInfo.email,
                    sex: this.patientInfo.sex,
                    isVipReservation: this.patientInfo.isVipReservation ? this.patientInfo.isVipReservation : false
                },
                chiefComplaint: this.visitInfo.chiefComplaint,
                diagnosticConcerns: this.visitInfo.diagnosticConcerns,
                eta: this.visitInfo.eta,
                modeOfTransport: this.visitInfo.modeOfTransport,
                visitedBefore: this.visitInfo.visitedBefore,
                clinic: {
                    id: this.referralInfo.selectedClinic || -1

                },
                provider: {
                    id: !this.referralInfo.selectedClinic ? -1 : this.referralInfo.selectedProvider || -1
                }
            };
            if (this.isPetFacility) {
                model.petInfo = {
                    petName: this.petInfo.petName,
                    petSpecies: this.petInfo.petSpecies,
                    petSubSpecies: this.petInfo.petSubSpecies,
                    petBreed: this.petInfo.petBreed,
                    petColor: this.petInfo.petColor,
                    petAge: this.petInfo.petAge,
                    petSex: this.petInfo.petSex,
                };
            }
            if (model.clinic.id === -1) {
                Object.assign(model.clinic,
                    {
                        id: -1,
                        isActive: true,
                        name: this.referralInfo.newClinic.name,
                        phone: this.referralInfo.newClinic.phone,
                        clientId: parseInt(this.$store.state.clientId)
                    });
            }
            if (model.provider.id === -1) {
                model.provider =
                {
                    id: -1,
                    isActive: true,
                    firstName: this.referralInfo.newProvider.firstName,
                    lastName: this.referralInfo.newProvider.lastName,
                    credentials: this.referralInfo.newProvider.credentials,
                    clientId: parseInt(this.$store.state.clientId)
                };
            }
            if (model.clinic.id === -1 || model.provider.id === -1) {
                model.provider =
                {
                    isActive: true,
                    firstName: this.referralInfo.newProvider.firstName,
                    lastName: this.referralInfo.newProvider.lastName,
                    credentials: this.referralInfo.newProvider.credentials,
                    clientId: parseInt(this.$store.state.clientId)
                };
                model.provider.clinics = [{
                    clinicId: model.clinic.id || -1,
                    dataRecords: [
                        {
                            type: 'Email',
                            value: this.referralInfo.newProvider.email
                        },
                        {
                            type: 'Phone',
                            value: this.referralInfo.newProvider.phone,
                            additional: this.referralInfo.newProvider.phoneType
                        }
                    ]
                }];
            }

            const payload = {
                model: model,
                files: this.visitInfo.files
            }

            this.$store.state.isSubmitting = true;
            // console.log('asdasdasdsadas', payload)
            this.$store.dispatch('submitReferral', payload).then(resp => {
                if (resp.data.success) {
                    this.$store.state.confirmation = resp.data.result;
                    this.$router.push({
                        name: 'confirmation',
                        params: {
                            clientId: this.$store.state.clientId,
                            confirmationNumber: resp.data.result.confirmationNumber
                        }
                    });
                } else {
                    this.$store.state.submissionError = resp.data.message;
                    this.$store.state.showSubmissionError = true;
                }
            }).finally(() => this.$store.state.isSubmitting = false);

        }
    }
}