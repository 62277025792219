<template>
    <v-container fluid fill-height>
        <v-row>
            <v-col class="d-flex justify-center align-center flex-column">
                <div class="facility-selector">
                    <logo class="mb-8"></logo>
                    <div class="header" v-if="localizedSettings" v-html="localizedSettings['passport_facility_selector_text']">
                    </div>
                    <v-select v-model="selectedFacility"
                              id="selector"
                              :items="childFacilities"
                              :allow-overflow="false"
                              :disable-lookup="true"
                              placeholder="Select location"
                              height="50"
                              class="mt-5"
                              background-color="white"
                              outlined
                              solo>
                    </v-select>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import logo from '../referral_form/logo'
    import referralMixin from '../../mixins/referral'

    export default {
        name: 'Landing',
        mixins: [referralMixin],
        data() {
            return {
                selectedFacility: null
            }
        },
        computed: {
            childFacilities() {
                if (!this.$store.state.clientData.children.length) return [];
                return this.$store.state.clientData.children.map(f => {
                    return {
                        text: f.shortClientName,
                        value: f.clientId
                    }
                })
            }
        },
        watch: {
            selectedFacility() {
                this.$router.push({
                    name: 'referral',
                    params: { clientId: this.selectedFacility }
                });
            }
        },
        components: {
            logo
        }
    };
</script>

<style>
    .facility-selector {
        width: 100%;
        text-align: center;
    }
    .facility-selector .header {
        font-size: 18px;
        margin-bottom: 20px;
        color: #333;
    }
    .facility-selector .v-select__selection {
        max-height: 40px;
    }
    .facility-selector > .v-input {
        width: 50%;
        max-width: 400px;
        min-width: 300px;
        margin: 0 auto;
    }
</style>
